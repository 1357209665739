export const LOCALE_KEYS = ["en", "it", "fr", "de", "es"] as const

interface LocaleSettings {
	flag: string
	name: string
	iso: string
	file: string
	ui: boolean
}

export const LOCALES: Record<(typeof LOCALE_KEYS)[number], LocaleSettings> = {
	en: {
		flag: "🇬🇧",
		name: "English",
		iso: "en-GB",
		file: "en.ts",
		ui: true,
	},
	it: {
		flag: "🇮🇹",
		name: "Italiano",
		iso: "it-IT",
		file: "it.ts",
		ui: true,
	},
	fr: {
		flag: "🇫🇷",
		name: "Français",
		iso: "fr-FR",
		file: "fr.ts",
		ui: true,
	},
	de: {
		flag: "🇩🇪",
		name: "Deutsch",
		iso: "de",
		file: "de.ts",
		ui: false,
	},
	es: {
		flag: "🇪🇸",
		name: "Español",
		iso: "es-ES",
		file: "es.ts",
		ui: false,
	},
} as const
